import { useContext, useEffect } from "react";
import { StepperContext } from "../contexts/StepperContext";
import "react-international-phone/style.css";
import toast from "react-hot-toast";

const SubCategorie = ({ subCategorie, selectedValue, setselectedValue }) => {
  const { userData, setUserData, setLoading } = useContext(StepperContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSelection = (element) => {
    if (selectedValue.includes(element)) {
      const updatedSelectedValue = selectedValue.filter(
        (value) => value !== element
      );
      setselectedValue(updatedSelectedValue);
    } else {
      if (selectedValue.length < 3) {
        setselectedValue([...selectedValue, element]);
      } else {
        toast.error("Nombre maximal de choix atteint (3)");
      }
    }
  };

  const handleUsedApplicationsChange = (e) => {
    const selectedOption = e.target.value;
    setUserData({ ...userData, application: selectedOption });
  };

  const handleHowDidYouHearChange = (e) => {
    const selectedOption = e.target.value;
    setUserData({ ...userData, you: selectedOption });
  };
  let applicationInput = null;
  if (userData.application === "autre") {
    applicationInput = (
      <input
        type="text"
        placeholder="Entrez le nom de l'application"
        // Vous pouvez ajouter des attributs ou des fonctions de gestion des changements ici
        className="w-full outline-none border-none bg-gray-100 p-1 mt-1 rounded"
      />
    );
  }

  let otherInput = null;

  if (userData.you === "autre") {
    otherInput = (
      <div className="mt-2">
        {/* <label htmlFor="otherInput" className="text-gray-500 text-xs uppercase">Où avez-vous entendu parler de You?</label> */}
        <input
          type="text"
          id="otherInput"
          placeholder="Entrez l'endroit ici"
          // Vous pouvez ajouter des attributs ou des fonctions de gestion des changements ici
          className="w-full outline-none border-none bg-gray-100 p-1 mt-1 rounded"
        />
      </div>
    );
  }

  useEffect(() => {
    if (setLoading && typeof setLoading == "function") {
      setLoading(false);
    }
  }, []);

  return (
    <div className="flex flex-col">
      <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          Sélectionnez vos sous-catégories (Veuillez choisir 3)*
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
          <fieldset className="flex flex-row">
            <div className="flex flex-row flex-wrap justify-between ">
              {subCategorie.map((element, i) => (
                <div
                  key={i}
                  onClick={() => handleSelection(element)}
                  className={`relative flex gap-x-2 w-[45%] md:w-full lg:w-[30%] align-center py-2 mx-2 justify-center mb-6 p-1 border-solid border-2 border-gray-100 cursor-pointer  transition-all rounded-md ${selectedValue?.includes(element)
                    ? "border-otherPrimaryColor"
                    : "hover:border-green-200 border-gray-100"
                    }`}
                >
                  <div className="flex items-center">
                    <img
                      className="place-content-center min-w-[30px]"
                      src={`http://cdn.file-manager.youaps.com/${element.icon.type}/${element.icon.filename}`}
                      alt="logo"
                      width={30}
                      height={50}
                    />
                  </div>

                  <div className="text-sm font-medium text-gray-900 my-auto ">
                    {element?.name}
                  </div>

                  {selectedValue && selectedValue[0] === element && (
                    <span className="badge absolute p-3 text-sm font-medium bg-otherPrimaryColor text-white top-[-10px] right-0 py-0 rounded">
                      Principal
                    </span>
                  )}
                </div>
              ))}
            </div>
          </fieldset>
        </div>
      </div>

      {/* <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          Site web de l'entreprise
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
          <input
            onChange={handleChange}
            value={userData["web"] || ""}
            name="web"
            placeholder="Entrez le site web de l'entreprise"
            type="url"
            className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
          />
        </div>
      </div> */}

      {/* <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          Quelles applications utilisées vous avant?*
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
          <select
            value={userData["application"] ?? "Aucune"}
            onChange={handleUsedApplicationsChange}
            className="w-full outline-none border-none"
          >
            <option value={"aucune"}>Aucune</option>
            <option value={"fresha"}>Fresha</option>
            <option value={"planity"}>Planity</option>
            <option value={"autre"}> Autre</option>
          </select>
        </div>
        {applicationInput}
      </div> */}

      <div className="w-full mx-2 flex-1"></div>

      <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          Comment avez vous entendu parler de you?*
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
          <select
            value={userData["you"] ?? "Sur les réseaux"}
            onChange={handleHowDidYouHearChange}
            className="w-full outline-none border-none"
          >
            {/* block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 */}
            <option value={"reseaux"}>Sur les réseaux sociaux</option>
            <option value={"tele"}>A la télé</option>
            <option value={"ami"}>Par un ami</option>
            <option value={"panneaux"}> Par les panneaux publicitaires</option>
            <option value={"autre"}> Autre</option>
          </select>
        </div>
        {otherInput}
      </div>
    </div>
  );
};

export default SubCategorie;
