import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

/*global google*/

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "300px",
};
const initCenter = {
  lat: 6.3, // default latitude
  lng: 1.1, // default longitude
};

const Map = ({
  society,
  setAddress_,
  setLocation,
  location,
  address_,
  country,
}) => {
  const KEY = process.env.REACT_APP_MAP_KEY;
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: KEY,
    libraries,
  });

  const initGivenLocation = location
    ? {
        lat: parseFloat(location?.split(",").at(0) ?? 0),
        lng: parseFloat(location?.split(",").at(1) ?? 0),
      }
    : {};

  const [address, setAddress] = useState(address_ ?? "");
  const [selected, setSelected] = useState(
    location && location?.split(",")?.length && location !== ""
      ? initCenter
      : {}
  );
  const [center, setCenter] = useState(
    location && location?.split(",")?.length && location
      ? initGivenLocation
      : initCenter
  );
  //
  useEffect(() => {
    if (!location && !location?.split(",")?.length) {
      // window.navigator.geolocation.getCurrentPosition(
      //   (pos) => {
      //     setCenter({
      //       lat: pos.coords.latitude,
      //       lng: pos.coords.longitude,
      //     });
      //     setTimeout(() => {
      //       setSelected({
      //         lat: pos.coords.latitude,
      //         lng: pos.coords.longitude,
      //       });
      //     }, 1000);
      //   },
      //   (err) => {
      //     if (err) {
      //       setCenter(initCenter);
      //     }
      //   }
      // );
    } else {
      setCenter(initGivenLocation);
      setSelected(initGivenLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof setAddress_ == "function" && address) {
      setAddress_(address);
    }
    if (typeof setLocation == "function" && selected) {
      setLocation(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, selected]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div className="flex flex-col items-center  ">
      <PlacesAutocomplete
        setSelected={setSelected}
        setCenter={setCenter}
        setAddress={setAddress}
        address_={address_}
        country={country}
      />
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={13}
        center={Object.keys(center).length ? center : initCenter}
        mapContainerClassName="map-container"
        tilt={45}
        onClick={(e) => {
          if (e.latLng.lng() && e.latLng.lat()) {
            setSelected({
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            });
          }
        }}
      >
        {Object.keys(selected).length && (
          <Marker
            position={Object.keys(selected).length ? selected : center}
            label={society ?? "Votre entreprise"}
            draggable={true}
            onDrag={(e) => {
              if (e.latLng.lng() && e.latLng.lat()) {
                setSelected({
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                });
              }
            }}
            marker
            icon={{
              url: require("../../location--filled.svg").default,
              fillColor: "#FFF",
              scale: 8,
            }}
            animation={google.maps.Animation.DROP}
            onDragEnd={() => {
              setCenter(selected);
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default Map;

const PlacesAutocomplete = ({
  setSelected,
  setCenter,
  setAddress,
  address_,
  country,
}) => {
  let requestOptions = { componentRestrictions: { country } };
  let withRestrictions = usePlacesAutocomplete({ requestOptions });
  let withoutRestrictions = usePlacesAutocomplete();

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = country ? withRestrictions : withoutRestrictions;

  let handleSelect = async (address) => {
    if (address) {
      setAddress(address);
      setValue(address, false);
      clearSuggestions();
      const results = await getGeocode({
        address,
      });
      if (results.length) {
        const { lat, lng } = await getLatLng(results[0]);
        setSelected({ lat, lng });
        setCenter({ lat, lng });
      }
    }
  };

  useEffect(() => {
    setValue(address_, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address_]);

  return (
    <>
      <input
        value={value}
        className="border-[1px] rounded-sm text-sm m-2 w-full px-2 py-2 outline-none "
        onChange={(val) => {
          setValue(val.target.value);
        }}
        disabled={!ready}
        placeholder="Enter your shop address"
      />
      <div className="flex flex-col items-start justify-start w-full ">
        <div className="font-bold h-6 mt-1 text-gray-500 text-xs leading-8 uppercase">
          Location of your company
        </div>
        <div className="text-[11px] italic font-medium ">
          Move the marker for more precision on the location of your shop.
        </div>
      </div>

      <div className="relative w-full flex justify-center">
        <div className="w-2/3 absolute z-10 bg-white ">
          {status === "OK" &&
            data.map((el, i) => {
              return (
                <span
                  key={i}
                  className="px-2 py-2 border-[1px] cursor-pointer transition-all border-gray-100 block w-full "
                  onClick={async () => {
                    if (el && el.description) {
                      await handleSelect(el.description);
                    }
                  }}
                >
                  {el.description}
                </span>
              );
            })}
        </div>
      </div>
    </>
  );
};
