import { useContext, useEffect, useState } from "react";
import { StepperContext } from "../contexts/StepperContext";
import { FiEye, FiEyeOff } from "react-icons/fi";


const MailAdress = () => {
  const { userData, setUserData, setLoading } = useContext(StepperContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  

  useEffect(() => {
    if (setLoading && typeof setLoading == "function") {
      setLoading(false);
    }
  }, []);

  return (
    <div className="flex flex-col">
      <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          E-mail*
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
          <input
            onChange={handleChange}
            value={userData["email"] || ""}
            name="email"
            placeholder="Entrez votre adresse mail"
            type="email"
            className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
            required
          />
        </div>
      </div>
      <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          Mot de passe*
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded relative ">
          <input
            onChange={handleChange}
            value={userData["password"]}
            name="password"
            placeholder="Entrer votre Mot de passe"
            type={passwordVisible ? "text" : "password"}
            className="p-1 px-2 appearance-none outline-none w-full text-gray-800 py-2 pr-10"
          />
          <span
            className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? (
              <FiEye size={20} /> // Utilisation de l'icône d'œil barré
            ) : (
              <FiEyeOff size={20} /> // Utilisation de l'icône d'œil
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MailAdress;
