// import logo from './logo.svg';
import "./App.css";
import { useEffect, useState } from "react";
import { StepperContext } from "./components/contexts/StepperContext";
import Stepper from "./components/Stepper";
import axios from "axios";

import { isValidPhoneNumber } from "libphonenumber-js";
import toast, { Toaster } from "react-hot-toast";
import MailAdressEn from "./components/steps/MailAdressEn";
import AccountEn from "./components/steps/AccountEn";
import EntrepriseInfoEn from "./components/steps/EntrepriseInfoEn";
import SubCategorieEn from "./components/steps/subCategorieEn";
import FinalEn from "./components/steps/FinalEn";
import StepperControlEn from "./components/StepperControlEn";
import Cookies from "js-cookie";

function AppEn() {
  const [country, setCountry] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedValue, setselectedValue] = useState([]);
  const [loading, setLoading] = useState(false);

  const [finalData, setFinalData] = useState([]);
  const [userCountry, setUserCountry] = useState("FR");

  const [hasPredicted, setHasPredicted] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState({
    name1: "",
    name2: "",
    username_owner: "",
    email: "",
    phoneNumber: "",
    name_society: "",
    password: "",
    country: "1",
    web: "",
    phone: "",
    application: "aucune",
    isocode: "",
    address: "",
    adress2: "",
    plage: "only me",
    society_subcategories: "",
    you: "reseaux",
  });

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const API = `${process.env.REACT_APP_YOU_API}/own/initialize`;

  useEffect(() => {
    let cookiePage = Cookies.get("currentPage");
    console.log({ cookiePage });
    if (cookiePage) {
      setCurrentStep(parseInt(cookiePage) ?? 1);
      setUserData({
        ...userData,
        ...(Cookies.get("userEmailInfo")
          ? JSON.parse(Cookies.get("userEmailInfo"))
          : {}),
        ...(Cookies.get("userPersonalInfo")
          ? JSON.parse(Cookies.get("userPersonalInfo"))
          : {}),
        ...(Cookies.get("companyInfo")
          ? JSON.parse(Cookies.get("companyInfo"))
          : {}),
        ...(Cookies.get("subCategoriesInfo")
          ? JSON.parse(Cookies.get("subCategoriesInfo"))
          : {}),
      });
    }
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: API,
    })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCountry(res.data.data.countries);
          setSubCategories(res.data.data.subcategories);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasPredicted) {
      try {
        fetch(
          `${process.env.REACT_APP_IPINFO_URL}/country?token=${process.env.REACT_APP_IPINFO_KEY}`
        )
          .then((response) => {
            if (response.ok) {
              return response.text();
            }
            return null;
          })
          .then((data) => {
            if (data) {
              setUserCountry(data?.trim());
            }
          })
          .finally(() => {
            // setHasPredicted(true);
          });
      } catch (error) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPredicted]);

  async function initialize() {
    return axios
      .post(
        `${process.env.REACT_APP_YOU_API}/requests/subscribe/business/initialize?_lang=en`,
        {
          owner_email: userData["email"],
        },
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.error === true) {
            if (res.data.exception === "ALREADY_EMAIL") {
              toast.error(
                "You already have accounts associated with this email address"
              );
              setLoading(false);
              return res.data.exception;
            }
            setLoading(false);
            return false;
          }
          setLoading(false);
          return res.data?.data;
        }
        toast.errror("The request failed with the status code ");
        setLoading(false);
        return false;
      })
      .catch((err) => {
        setLoading(false);
        return false;
      });
  }

  async function initializeForce() {
    let url = `${process.env.REACT_APP_YOU_API}/requests/subscribe/business/force/initialize?_you_forced=YOU-FORCE-INSTANCE-D65DIA38QHV15TCS44-${userData["email"]}&_lang=en`;

    return axios
      .post(
        url,
        {
          owner_email: userData["email"],
        },
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          return res.data?.data;
        } else {
          setLoading(false);
          return false;
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  async function personalInformation() {
    let body = {
      owner_name: userData["name_owner"],
      owner_surname: userData["username_owner"],
      owner_country: userData["country"],
      owner_phone:
        userData["isocode"] + "you" + userData["phone"].replace(/ /, "you"),
      // owner_phone:
      //   userData["isocode"] + "you" + userData["phone"].split(" ").join("you"),
      owner_password: userData["password"],
      owner_email: userData["email"],
      instance_token: token,
    };
    return axios
      .post(
        `${process.env.REACT_APP_YOU_API}/requests/subscribe/business/personal/informations?_lang=en`,
        body,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.error === true) {
            console.log(body);

            toast.error(res.data.message);
            setLoading(false);
            return false;
          } else {
            toast.success(res.data.message);
            setLoading(false);
            return res.data?.data;
          }
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  async function entrepriseInformation() {
    let body = {
      society_name: userData["name_society"],
      society_website:
        userData["web"] !== "" && userData["web"] !== null
          ? userData["web"]?.startsWith("http")
            ? userData["web"]
            : "http://" + userData["web"]
          : "",
      society_subcategories: selectedValue
        .map((el) => {
          return el["id"];
        })
        .join(","),
      society_people: userData["plage"],
      society_address: userData["address"],
      society_location: userData["location"],
      wich_app_using: userData["application"],
      how_do_you_hear: userData["you"],
      instance_token: token,
      owner_email: userData["email"],
    };
    return axios
      .post(
        `${process.env.REACT_APP_YOU_API}/requests/subscribe/business/enterprise/informations?_lang=en`,
        body,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.error === true) {
            toast.error(res.data.message);
            setLoading(false);
            return false;
          } else {
            setLoading(false);
            return res.data?.data;
          }
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  const steps = [
    "Email address",
    "Personal information",
    "Company information",
    "Subcategory",
    "Complete",
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <MailAdressEn />;
      case 2:
        return <AccountEn countries={country} />;
      case 3:
        return <EntrepriseInfoEn />;
      case 4:
        return (
          <SubCategorieEn
            subCategorie={subCategories}
            selectedValue={selectedValue}
            setselectedValue={setselectedValue}
          />
        );
      case 5:
        return <FinalEn />;
      default:
    }
  };

  let checkNullorEmpty = (param) => {
    return param == null || param === "";
  };

  function ValidateEmail(input = "") {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;

    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  const [token, setToken] = useState(null);

  const handleNextStep = () => {
    let newStep = currentStep + 1;
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  async function callApi() {
    if (currentStep === 1) {
      let result = await initialize();
      if (result) {
        if (result === "ALREADY_EMAIL") {
          setIsOpen(true);
          setLoading(false);
          result = null;
        } else {
          setToken(result);
          setLoading(false);
          // nextPage("next");
          handleNextStep();
          return;
        }
      } else {
        result = null;
      }
    } else if (currentStep === 2) {
      let personalInfo = await personalInformation();
      if (personalInfo) {
        setToken(personalInfo);
        setLoading(false);
        // nextPage("next");
        handleNextStep();
        return;
      } else {
        personalInfo = null;
      }
    } else if (currentStep === 3) {
      setLoading(false);
      handleNextStep();
      // nextPage("next");
    } else if (currentStep === 4) {
      let entrepriseInfo = await entrepriseInformation();
      if (entrepriseInfo) {
        setLoading(false);
        // nextPage("next");
        Cookies.remove("currentPage");
        Cookies.remove("userEmailInfo");
        Cookies.remove("userPersonalInfo");
        Cookies.remove("companyInfo");
        Cookies.remove("subCategoriesInfo");
        handleNextStep();
        return;
      } else {
        entrepriseInfo = null;
      }
    }
  }

  const hasUpperCase = /[A-Z]/.test(userData["password"]);
  const hasLowerCase = /[a-z]/.test(userData["password"]);
  const hasNumber = /\d/.test(userData["password"]);
  const hasMinLength = userData["password"].length >= 8;

  const isValidPassword =
    hasUpperCase && hasLowerCase && hasNumber && hasMinLength;

  const handleClick = async (direction) => {
    setLoading(true);

    let newStep = currentStep;

    if (direction !== "next") {
      newStep = currentStep - 1;
      newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
      setLoading(false);
      return;
    }

    let invalid = false;
    let errorMessage = "";

    switch (currentStep) {
      case 1:
        if (
          checkNullorEmpty(userData["email"]) ||
          checkNullorEmpty(userData["password"]) ||
          !ValidateEmail(userData["email"]) ||
          !isValidPassword
        ) {
          invalid = true;
          errorMessage = "";

          if (checkNullorEmpty(userData["email"])) {
            errorMessage += "Email address is required. ";
          } else if (!ValidateEmail(userData["email"])) {
            errorMessage += "Please enter a valid email address. ";
          }

          if (checkNullorEmpty(userData["password"])) {
            errorMessage += "Password is required. ";
          } else if (!isValidPassword) {
            errorMessage +=
              "The password must contain at least one uppercase letter, one lowercase letter, and one digit.";
            // Add specific conditions for a valid password here
          }

          invalid = true;
        }
        break;
      case 2:
        if (
          checkNullorEmpty(userData["name_owner"]) ||
          checkNullorEmpty(userData["username_owner"]) ||
          (!checkNullorEmpty(userData.phone) &&
            !isValidPhoneNumber(userData["phone"], userData["isocode"]))
        ) {
          invalid = true;
          errorMessage = "Plaese check personnal informations";
        }
        break;
      case 3:
        if (
          checkNullorEmpty(userData["name_society"]) ||
          checkNullorEmpty(userData["address"]) ||
          checkNullorEmpty(userData["location"])
        ) {
          invalid = true;
          errorMessage = "Please check entreprise information";
        }
        break;
      case 4:
        if (selectedValue.length !== 3) {
          invalid = true;
          errorMessage = "Please choose 3 professions";
        }
        break;
      default:
        break;
    }

    if (invalid) {
      toast.error(errorMessage);
      setLoading(false);
      return;
    }
    // Enregistrement des données dans les cookies
    Cookies.set("currentPage", currentStep);
    switch (currentStep) {
      case 1:
        const userEmailInfoString = JSON.stringify({
          email: userData["email"],
          password: userData["password"],
        });
        Cookies.set("userEmailInfo", userEmailInfoString, { expires: 2 });
        // console.log(typeof userData.society_subcategories);

        break;
      case 2:
        const userPersonalInfoString = JSON.stringify({
          name_owner: userData["name_owner"],
          username_owner: userData["username_owner"],
          country: userData["country"],
          email: userData["email"],
          isocode: userData["isocode"],
          phone: userData["phone"],
          password: userData["password"],
        });
        Cookies.set("userPersonalInfo", userPersonalInfoString, { expires: 2 });
        break;
      case 3:
        const companyInfoString = JSON.stringify({
          name_society: userData["name_society"],
          web: userData["web"],
          plage: userData["plage"],
          address: userData["address"],
          location: userData["location"],
          application: userData["application"],
          you: userData["you"],
          email: userData["email"],
        });
        Cookies.set("companyInfo", companyInfoString, { expires: 2 });
        break;
      case 4:
        const subCategoriesString = JSON.stringify({
          email: userData["email"],
          password: userData["password"],
        });
        Cookies.set("subCategoriesInfo", subCategoriesString, { expires: 2 });
        break;
      default:
        break;
    }

    // Passage à l'étape suivante si tout est valide
    newStep += 1;
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
    setLoading(false);
  };

  return (
    <div className="bg-arriere bg-contain flex flex-col items-center justify-center h-screen relative ">
      <div className="absolute top-0 h-screen w-screen bg-black backdrop-blur-sm bg-opacity-50 "></div>
      {modalIsOpen && (
        <div
          className={` absolute self-center flex flex-col items-center justify-center z-50 top-0 left-0 w-screen bg-black bg-opacity-60 h-screen ${
            modalIsOpen ? "flex" : "hidden"
          }`}
        >
          <div className="md:w-1/2 w-3/4 bg-white self-center border-2 flex flex-col items-center justify-center py-7 rounded-md shadow-lg ">
            <div className="text-center font-medium  ">
              Your email already exists. Do you want to create with the same
              one?
            </div>
            <div className="container flex px-10 mt-4 mb-8 w-2/3 items-center justify-evenly">
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                className={
                  "bg-white text-slate-400 capitalize py-2 px-4 rounded-xl font-semibold cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out"
                }
              >
                No
              </button>
              <button
                onClick={async () => {
                  let res = await initializeForce();
                  if (res) {
                    setToken(res);
                    handleNextStep();
                  }
                  setIsOpen(false);
                }}
                className={
                  "bg-white text-slate-400 capitalize py-2 px-4 rounded-xl font-semibold cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out "
                }
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="md:w-2/3 overflow-hidden max-h-[90%] mx-auto my-auto shadow-xl rounded-2xl pb-2 z-[1] bg-white flex flex-col border-2  ">
        <div className="flex items-center justify-between capitalize mt-1 ">
          {/* <a href="/"> <img src="../images/Flag_of_England.png" className="w-6 h-4 " width={20} height={20} /></a> */}
          <div className="mt-3 ml-10">
            <img
              src="../images/you_logo_black.PNG"
              alt=""
              className=""
              width={150}
              height={150}
            />
          </div>
          <div className="flex items-center">
            <a href="/fr">
              <img
                src="../images/fr.svg"
                alt=""
                className="w-6 h-4 "
                width={40}
                height={40}
              />
            </a>

            <a
              href="/fr"
              className="text-sm border-solid underline text-gray-500 rounded-md -0 mr-10 "
            >
              FR
            </a>
          </div>
        </div>

        {/* Stepper */}
        <div className="container horizontal mt-3">
          <Stepper steps={steps} currentStep={currentStep} />
        </div>

        <div
          className="flex flex-auto h-full flex-col overflow-auto mt-10"
          style={{ scrollbarWidth: "none" }}
        >
          {/* Display Components */}
          <div className="px-10 mt-0 ">
            <StepperContext.Provider
              value={{
                userData,
                setUserData,
                finalData,
                setFinalData,
                setLoading,
                userCountry,
                setUserCountry,
                country,
                setHasPredicted,
                hasPredicted,
              }}
            >
              {displayStep(currentStep)}
            </StepperContext.Provider>
          </div>

          <Toaster position="top-center" />

          {/* Naviagtion control */}
          {steps.length !== currentStep && (
            <StepperControlEn
              handleClick={handleClick}
              currentStep={currentStep}
              steps={steps}
              setLoading={setLoading}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AppEn;
