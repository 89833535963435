import React, { useContext, useEffect } from "react";
import { StepperContext } from "../contexts/StepperContext";

import MapEn from "../map/MapEn";

const EntrepriseInfoEn = () => {
  const { userData, setUserData, setLoading, country } =
    useContext(StepperContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({ ...prevUserData, [name]: value }));
  };

  const handleEmployeeRangeChange = (e) => {
    const selectedOption = e.target.value;
    setUserData({ ...userData, plage: selectedOption });
  };
  useEffect(() => {
    if (setLoading && typeof setLoading == "function") {
      setLoading(false);
    }
  }, []);

  return (
    <div className="flex flex-col">
      <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          Company name*
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
          <input
            onChange={handleChange}
            value={userData["name_society"] || ""}
            name="name_society"
            placeholder="Enter the company name"
            className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
          />
        </div>
      </div>

      <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          Employee range*
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
          <select
            onChange={handleEmployeeRangeChange}
            value={userData["plage"] || ""}
            className="w-full outline-none border-none"
          >
            {/* block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 */}
            <option value={"only me"}>Only me</option>
            <option value={"1-10"}>1-10</option>
            <option value={"11-50"}>11-50</option>
            <option value={"51-99"}>50-99</option>
            <option value={"more"}>100+</option>
          </select>
        </div>
      </div>

      <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          Company address*
        </div>
        <MapEn
          country={
            country?.find((el) => {
              return el["id"] == userData["country"];
            })
              ? country
                  ?.find((el) => {
                    return el["id"] == userData["country"];
                  })
                  ["isocode"]?.toLowerCase()
              : null
          }
          society={userData["name_society"] ?? "Votre boutique"}
          address_={userData["address"]}
          location={userData["location"]}
          setAddress_={(address) => {
            setUserData((prevUserData) => ({
              ...prevUserData,
              address: address,
            }));
          }}
          setLocation={(location) => {
            setUserData((prevUserData) => ({
              ...prevUserData,
              location: Object.values(location).join(","),
            }));
          }}
        />
      </div>
    </div>
  );
};

export default EntrepriseInfoEn;
