import React from "react";

const StepperControlEn = ({ handleClick, currentStep, steps, setLoading, loading }) => {

    return (
        <div className={`container flex ${currentStep !== 1 ? "justify-between" : "justify-end"} px-10 mt-4 mb-8`}>
            {/* Back Button  */}
            {currentStep !== 1 && (
                <button
                    onClick={() => handleClick()}
                    className={
                        `bg-white text-slate-400 capitalize py-2 px-4 rounded-xl font-semibold cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out ${currentStep === 1 ? "opacity-50 cursor-not-allowed" : ""}`
                    }
                >
                    Return
                </button>
            )}
            {/* Next Button */}
            <button
                onClick={() => {
                    handleClick("next")
                    // setLoading(true)
                }}
                className="bg-otherPrimaryColor text-white capitalize py-2 px-4 rounded-xl
        font-semibold cursor-pointer border-2
         hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out"
            >

                {loading === true ? (
                    <svg
                        version="1.1"
                        id="L9"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        enableBackground="new 0 0 0 0"
                        xmlSpace="preserve"
                    >
                        <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite"
                            />
                        </path>
                    </svg>
                ) : (
                    currentStep === steps.length - 1 ? "Confirm" : "Next"
                )}
            </button>
        </div>
    );
};

export default StepperControlEn;
