import React from "react";

const FinalEn = () => {
  return (
    <div className="container md:mt-10">
      <div className="flex flex-col items-center">
        <div className="text-otherPrimaryColor">
          <img
            className="place-content-center"
            src="../images/OIPP.png"
            alt="logo"
            width={150}
            height={150}
          />
        </div>
        <div className="mt-3 text-xl font-semibold capitalize text-black-500">
          Congratulations!
        </div>
        <div className="text-lg font-semibold text-gray-500 w-1/2 text-center mt-5">
          Your company has been created. An email containing your login
          credentials for You Business has been sent to you.
        </div>
        {/* {/* <a className='mt-10' href='/'>
          <button className='h-10 px-5 text-red-700 transition-colors duration-150 border border-gray-300 rounded-lg focus:shadow-outline hover:bg-red-500 hover:text-green-100'>
            Fermer
          </button> */}

        <div className="mt-5 flex space-x-4">
          <img
            src="../images/appstore2.jpg"
            alt="Play Store"
            width={140}
            height={60}
          />
          <img
            src="../images/appstorrre.webp"
            alt="App Store"
            width={140}
            height={60}
          />
        </div>
      </div>
    </div>
  );
};

export default FinalEn;
