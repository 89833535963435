import { useContext, useEffect } from "react";
import { StepperContext } from "../contexts/StepperContext";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const Account = ({ countries }) => {
  const {
    userData,
    setUserData,
    setLoading,
    setUserCountry,
    userCountry,
    hasPredicted,
    setHasPredicted,
  } = useContext(StepperContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handlePhone = (phone, isocode) => {
    setUserData({ ...userData, phone: phone, isocode: isocode });
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setUserData({ ...userData, country: selectedCountry });
  };

  useEffect(() => {
    if (setLoading && typeof setLoading == "function") {
      setLoading(false);
    }
  }, [setLoading]);

  useEffect(() => {
    if (!hasPredicted) {
      let predictatedCountry = countries.find(
        (el) => el["isocode"] === userCountry
      );

      setUserData({
        ...userData,
        country: predictatedCountry ? predictatedCountry["id"] : 1,
      });

      setHasPredicted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [phone, setPhone] = useState('');
  return (
    <div className="flex flex-col">
      <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          Pays*
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
          <select
            id="country"
            name="country"
            autoComplete="country-name"
            className="w-full outline-none border-none bg-white py-2 px-1"
            onChange={handleCountryChange}
            value={userData["country"] ?? "1"}
          >
            {countries.map((element, i) => (
              <option key={i} value={element?.id}>
                {element?.name} ({element?.iso_currency})
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="w-full mx-2 flex-1">
        <div
          className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize
        "
        >
          Nom*
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
          <input
            onChange={handleChange}
            value={userData["name_owner"] || ""}
            name="name_owner"
            placeholder="Entrez votre nom"
            className="p-2 px-2 appearance-none outline-none w-full text-gray-800  bg-white"
          />
        </div>
      </div>

      <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          Prénoms*
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
          <input
            onChange={handleChange}
            value={userData["username_owner"] || ""}
            name="username_owner"
            placeholder="Entrez vos prénoms"
            className="p-2 px-2 appearance-none outline-none w-full text-gray-800 bg-white"
          />
        </div>
      </div>

      <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 capitalize">
          Téléphone*
        </div>
        <div className="w-full bg-white my-2 p-1 flex border border-gray-200 rounded">
          <PhoneInput
            defaultCountry={userCountry.toLowerCase() ?? "tg"}
            value={userData["phone"]}
            className="w-full"
            inputStyle={{
              width: "100%",
              outline: "none",
              border: "none",
              fontSize: 15,
            }}
            onChange={(phone, meta) => {
              handlePhone(meta.inputValue, meta.country.iso2?.toUpperCase());
              setUserCountry(meta.country.iso2?.toUpperCase());
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Account;
